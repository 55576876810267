<template>
  <div class="wrap">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="box xshy">
          <div class="t clearfix bbd pr10">
            <div class="tit fl">共识学习</div><a @click="goSPlist" class="more">更多&gt;&gt;</a>
          </div>
          <div class="c">
            <ul class="dotlist lh18">
              <li class="ellipsis cus_link" v-for="(item,i) in vlist" :key="'video'+i">
                <el-link :underline="false" @click="onClick(item)">
                讲师：{{ item.DoctorName }}
                </el-link>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box xshy">
          <div class="t clearfix bbd pr10">
            <div class="tit fl">共识指南文献</div><a class="more" @click="goWJlist">更多&gt;&gt;</a>
          </div>

          <div class="c">
            <ul class="dotlist lh18">
              <li class="ellipsis cus_link" v-for="(item,i) in flist" :key="'qcfile'+i">
                <el-link :underline="false" :href="item.Link" target="_blank">
                  {{ item.Name }}
                </el-link>
              </li>
            </ul>
          </div>

        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetQCVideos, GetQCFiles } from "@/api";

export default {
  name: "QCGF",
  data() {
    return {
      flist: [],
      vlist:[],
    }
  },
  methods: {
    async GetQCVideosAsync() {
      const r = await GetQCVideos();
      if (r.code == 1) {
        this.vlist = r.data;
        if (this.vlist.length > 0) {
          this.vlist = this.vlist.slice(0,5)
        }
      } else {
        this.$message.error(r.error.Message);
      }
    },
    async GetQCFilesAsync() {
      const r = await GetQCFiles();
      if (r.code == 1) {
        this.flist = r.data;
        if (this.flist.length > 0) {
          this.flist = this.flist.slice(0, 5)
        }
      } else {
        this.$message.error(r.error.Message);
      }
    },
    onClick(content) {
      sessionStorage.videoccLink = content.Link
      this.$router.push({
        path: `/VideoLesson/${content.Id}`,
      })
    },
    goSPlist() {
      this.$router.push('/LearnAndDiscussion')
    },
    goWJlist() {
      this.$router.push('/ZKWJ')
    },
  },
  mounted() {
    this.GetQCFilesAsync()
    this.GetQCVideosAsync()
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  margin: 0 auto;
}

.box {
  min-height: 600px;
  background-color: #fff;
}

.box .t {
  line-height: 40px;
  min-height: 40px;
  height: auto !important;
  height: 40px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
}

.bbd {
  border-bottom: 1px solid #eee;
}

.box .t .tit {
  height: 16px;
  line-height: 16px;
  color: #2b4a8f;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  /* background: url(../images/boxt.png) left center no-repeat; */
  margin-top: 12px;
}

a.more,
a.more:link,
a.more:visited {
  font-weight: normal;
  float: right;
  color: #777;
}

.pr10 {
  padding-right: 10px;
}

.lh18 {
  line-height: 1.8;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

ul,
li {
  list-style-type: none;
}

.dotlist li {
  /* background: url(../images/dot.png) left center no-repeat; */
  padding: 5px 0px;
  padding-left: 10px;
}

.ellipsis {}

.el-link::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #777;
  margin-right: 8px;
  vertical-align: middle;
}


a,
a:link {
  color: #777;
  transition: all 0.3s;
}

a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.cus_link {
  margin-top: 20px;
}

.cus_link>>>.el-link {
  font-size: 16px;
}

.cus_link>>>.el-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
</style>